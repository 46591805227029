.stacked-line {
  width: 100%;
  height: 88vh;
}
.chart-container {
  width: 100%;
  height: 100%;
}
.add-countries {
  position: absolute;
  top: 0;
  left:0;
  z-index: 1;
  padding-left: 15px;
  padding-top: 5px;
  display: flex;
}
.add-country-btn {
  height: 46px;
}
.play-pause {
  padding-left: 5px;
}
.slider-with-date {
  width: 100%;
}
.slider-selected-date {
    margin-left: 38%;
    font-size: medium;
    font-weight: bold;
}
.footer {
  display: flex;
}
.settings-btn {
  display: flex;
  margin-top: 20px;
  margin-top: 15px;
}
.settings-btn > Button {
  margin-right: 5px;
}